import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import YAML from 'js-yaml';
import "../css/wiki/wiki.scss";
import '../css/wiki/content.css';

const Wiki = () => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState({ groupIndex: 0, index: 0 });
  const [scrollVersion, setScrollVersion] = useState(0); // State to track scroll updates
  const [sections, setSections] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  
  // Which Minecraft Versions match which Wiki Versions
  const defaultVersion = 'Wiki_3.0';
  const wikiMinecraftVersions = {
    'Wiki_4.0': '1.21.4',
    'Wiki_3.0': '1.20.5 - 1.21.3',
    'Wiki_2.0': '1.13 - 1.20.4',
    'Wiki_1.0': '1.8.8 - 1.12.2',
  };

  // Default to the latest version
  const [currentVersion, setCurrentVersion] = useState(
    new URLSearchParams(location.search).get('version') || 
    localStorage.getItem('wikiCurrentMcVersion') || 
    defaultVersion
  );

  const handleSectionClick = async (groupIndex, index) => {
    setCurrentSectionIndex({ groupIndex, index });
    localStorage.setItem('wikiSectionIndex', JSON.stringify({ groupIndex, index }));
  
    const params = new URLSearchParams(location.search);
    params.set('groupIndex', groupIndex);
    params.set('index', index);
    navigate(`/wiki?${params.toString()}`);
  
    setScrollVersion((v) => v + 1); // Increment scroll version to trigger scroll update
  };


  // useEffect to handle scrolling based on URL hash changes and internal link clicks
  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    if (!hash) return;
  
    const attemptScrollToElement = () => {
      const element = document.getElementById(hash);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY - 60; // Offset 50px Navbar 10px Margin
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth',
        });
        clearInterval(scrollInterval);
      }
    };

    const scrollInterval = setInterval(attemptScrollToElement, 100);
    const timeout = setTimeout(() => clearInterval(scrollInterval), 3000);
  
    return () => {
      clearTimeout(timeout);
      clearInterval(scrollInterval);
    };
  }, [location.hash, scrollVersion]); // Depend on both the hash and the scrollVersion

  const fetchIndex = async () => {
    const indexPath = `./WikiDocuments/${currentVersion}/Index.yml`;
    const response = await fetch(indexPath);
    const yamlText = await response.text();
    const index = await YAML.load(yamlText); // Parse the YAML file content
    setSections(index.groups); // Assuming the YAML structure matches the expected state structure
    return index.groups;
  };

  useEffect(() => {
    const updateVersion = async () => {
      //set values for version change from button in game
      const searchParams = new URLSearchParams(location.search);
      if(wikiMinecraftVersions[currentVersion]){
        localStorage.setItem('wikiCurrentMcVersion', currentVersion);
      }
      searchParams.set('version', currentVersion);
      await fetchIndex();
      const currentHash = location.hash; // Get the current hash
      navigate('?' + searchParams.toString() + currentHash, { replace: true });
    };

    updateVersion();
  }, [currentVersion]);

  const fetchContent = async (groupIndex, index) => {
    const newSections = await fetchIndex();
    try{
      const response = await fetch(`./WikiDocuments/${currentVersion}/Pages/${newSections[groupIndex].items[index].fileName}`);
      const content = await response.text();
      const updatedSections = [...newSections];
      updatedSections[groupIndex].items[index].content = content;
      setSections(updatedSections);
    }catch(error){
      //move user to default section if section does not exist
      handleSectionClick(0,0);
    }
  };
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const groupIndexFromURL = searchParams.get('groupIndex');
    const indexFromURL = searchParams.get('index');
    let versionFromURL = searchParams.get('version');

    //cancel and call useEffect for version change if version is invalid in URL
    if(!wikiMinecraftVersions[versionFromURL] || versionFromURL == null){
      setCurrentVersion(localStorage.getItem('wikiCurrentMcVersion'));
      return;
    }
    
    if (groupIndexFromURL && indexFromURL) {
      const newSectionIndex = {
        groupIndex: parseInt(groupIndexFromURL, 10),
        index: parseInt(indexFromURL, 10),
      };
      setCurrentSectionIndex(newSectionIndex);
      localStorage.setItem('wikiSectionIndex', JSON.stringify(newSectionIndex));
      fetchContent(newSectionIndex.groupIndex, newSectionIndex.index);
    } else {
      const cachedIndex = localStorage.getItem('wikiSectionIndex');
      if (cachedIndex) {
        const parsedCachedIndex = JSON.parse(cachedIndex);
        setCurrentSectionIndex(parsedCachedIndex);
        const currentHash = location.hash; // Get the current hash
        navigate(`/wiki?groupIndex=${parsedCachedIndex.groupIndex}&index=${parsedCachedIndex.index}&version=${versionFromURL}${currentHash}`, { replace: true });
        fetchContent(parsedCachedIndex.groupIndex, parsedCachedIndex.index);
      } else {
        fetchContent(currentSectionIndex.groupIndex, currentSectionIndex.index);
      }
    }
  }, [location.search]);

  return (
    <div className="wikiContainer">
      <aside className="wikiSections">
        <ul>
          {sections && sections.length > 0 && sections.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <li className="groupTitle">{group.groupName}</li>
              {group.items.map((section, index) => (
                <li 
                  key={index} 
                  onClick={() => handleSectionClick(groupIndex, index)}
                  className={currentSectionIndex.groupIndex === groupIndex && currentSectionIndex.index === index ? 'selectedWikiSection' : ''}
                >
                  {section.title}
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
      </aside>
      <main className="wikiContent" style={{ maxWidth: '960px', margin: '0 auto', minHeight: '100vh' }}>
        {sections && sections.length > 0 &&
          sections[currentSectionIndex.groupIndex]?.items &&
          sections[currentSectionIndex.groupIndex].items.length > 0 &&
          sections[currentSectionIndex.groupIndex]?.items[currentSectionIndex.index] && (
            <section>
              <div className="wikiSectionTitle">
                <h1>{sections[currentSectionIndex.groupIndex].items[currentSectionIndex.index].title}</h1>
                <select 
                  value={currentVersion} 
                  onChange={(e) => setCurrentVersion(e.target.value)}
                  style={{ margin: '20px 0' }}
                >
                  {Object.keys(wikiMinecraftVersions).map((version) => (
                    <option key={version} value={version}>
                      MC {wikiMinecraftVersions[version]}
                    </option>
                  ))}
                </select>
              </div>
              <div dangerouslySetInnerHTML={{ __html: sections[currentSectionIndex.groupIndex].items[currentSectionIndex.index].content }} />
            </section>
        )}
      </main>
    </div>
  );
};

export default Wiki;